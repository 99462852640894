import { useState } from 'react';

/**
 * Secure Hash Algorithm (SHA1)
 * http://www.webtoolkit.info/
 **/
function SHA1(msg) {
  function rotate_left(n, s) {
    var t4 = (n << s) | (n >>> (32 - s));
    return t4;
  }
  function lsb_hex(val) {
    var str = '';
    var i;
    var vh;
    var vl;
    for (i = 0; i <= 6; i += 2) {
      vh = (val >>> (i * 4 + 4)) & 0x0f;
      vl = (val >>> (i * 4)) & 0x0f;
      str += vh.toString(16) + vl.toString(16);
    }
    return str;
  }
  function cvt_hex(val) {
    var str = '';
    var i;
    var v;
    for (i = 7; i >= 0; i--) {
      v = (val >>> (i * 4)) & 0x0f;
      str += v.toString(16);
    }
    return str;
  }
  function Utf8Encode(string) {
    string = string.replace(/\r\n/g, '\n');
    var utftext = '';
    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  }
  var blockstart;
  var i, j;
  var W = new Array(80);
  var H0 = 0x67452301;
  var H1 = 0xefcdab89;
  var H2 = 0x98badcfe;
  var H3 = 0x10325476;
  var H4 = 0xc3d2e1f0;
  var A, B, C, D, E;
  var temp;
  msg = Utf8Encode(msg);
  var msg_len = msg.length;
  var word_array = new Array();
  for (i = 0; i < msg_len - 3; i += 4) {
    j =
      (msg.charCodeAt(i) << 24) |
      (msg.charCodeAt(i + 1) << 16) |
      (msg.charCodeAt(i + 2) << 8) |
      msg.charCodeAt(i + 3);
    word_array.push(j);
  }
  switch (msg_len % 4) {
    case 0:
      i = 0x080000000;
      break;
    case 1:
      i = (msg.charCodeAt(msg_len - 1) << 24) | 0x0800000;
      break;
    case 2:
      i =
        (msg.charCodeAt(msg_len - 2) << 24) |
        (msg.charCodeAt(msg_len - 1) << 16) |
        0x08000;
      break;
    case 3:
      i =
        (msg.charCodeAt(msg_len - 3) << 24) |
        (msg.charCodeAt(msg_len - 2) << 16) |
        (msg.charCodeAt(msg_len - 1) << 8) |
        0x80;
      break;
  }
  word_array.push(i);
  while (word_array.length % 16 != 14) word_array.push(0);
  word_array.push(msg_len >>> 29);
  word_array.push((msg_len << 3) & 0x0ffffffff);
  for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
    for (i = 0; i < 16; i++) W[i] = word_array[blockstart + i];
    for (i = 16; i <= 79; i++)
      W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
    A = H0;
    B = H1;
    C = H2;
    D = H3;
    E = H4;
    for (i = 0; i <= 19; i++) {
      temp =
        (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5a827999) &
        0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    for (i = 20; i <= 39; i++) {
      temp =
        (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    for (i = 40; i <= 59; i++) {
      temp =
        (rotate_left(A, 5) +
          ((B & C) | (B & D) | (C & D)) +
          E +
          W[i] +
          0x8f1bbcdc) &
        0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    for (i = 60; i <= 79; i++) {
      temp =
        (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    H0 = (H0 + A) & 0x0ffffffff;
    H1 = (H1 + B) & 0x0ffffffff;
    H2 = (H2 + C) & 0x0ffffffff;
    H3 = (H3 + D) & 0x0ffffffff;
    H4 = (H4 + E) & 0x0ffffffff;
  }
  var temp =
    cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);

  return temp.toLowerCase();
}

const Thread = ({ content }) => {
  return (
    <div className='flex-fill mw-75 p-3 overflow-auto content h-100'>
      {content !== null
        ? content.map((post, i) => {
            const message = atob(post.message)
              .split('\n')
              .map((value, index) => {
                return (
                  <span key={index}>
                    {value}
                    <br />
                  </span>
                );
              });

            return (
              <div key={i} className='pb-5'>
                <p className='my-1'>
                  <span className='fw-bold'>Date:</span> {post.date}
                </p>
                <p className='my-1'>
                  <span className='fw-bold'>Author:</span> {btoa(post.author)}
                </p>
                <p className='my-1'>
                  <span className='fw-bold'>Message:</span>
                </p>
                <p className='my-1'>{message}</p>
              </div>
            );
          })
        : 'no thread'}
    </div>
  );
};

const Nav = ({ threads, selected, select }) => {
  let navThreads = [];

  for (let i = 0; i < threads.length; i++) {
    let style = 'flex-fill navborder p-3';

    if (i === selected) {
      style += ' selectedthread';
    }

    navThreads.push(
      <div key={i} className={style} onClick={() => select(i)}>
        {atob(threads[i].topic)}
      </div>
    );
  }

  return (
    <div className='content d-flex flex-column w-25 flex-shrink-0'>
      {navThreads || 'no threads'}
    </div>
  );
};

const MessageBoard = () => {
  const [selected, setSelected] = useState(0);

  const threads = [
    {
      topic: 'SGkhIQ==',
      content: [
        {
          date: 'November 12, 2015',
          author: '}©lµ§ß',
          message: `SGVsbG8gQXZlcnkhIEkndmUganVzdCBiZWVuIGluZm9ybWVkIHRoYXQgeW91IGFuZCBJIHdpbGwgYmUgd29ya2luZyB0b2dldGhlciBvbiByZXZhbXBpbmcgdGhlIENoYXJ0ZXIncyBhcmNoaXZlcyEgSSBsb29rIGZvcndhcmQgdG8gbGVhcm5pbmcgYWxsIGFib3V0IHRoZSBuZXdmYW5nbGVkIHRlY2hub2xvZ2ljYWwgYWR2YW5jZXMgeW91J3JlIGJyaW5naW5nIHRvIHRoZSB0YWJsZSEgSSdtIGEgYml0IG9mIGFuIG9sZCBmb2dleSB3aGVuIGl0IGNvbWVzIHRvIGFueXRoaW5nIGNvbXB1dGVyaXplZCBidXQgSSBwcm9taXNlIEknbSBhIGZhc3QgbGVhcm5lciEgSW4gdGhlIHNwaXJpdCBvZiBjYW1hcmFkZXJpZSwgaG93IGFib3V0IHdlIHRha2UgYSB0ZWFtIHBpY3R1cmUgdG9nZXRoZXI/IEkga25vdyB0aGUgQ2hhcnRlciBkb2Vzbid0IGxpa2UgdXMgcG9zdGluZyBhbnkgcGVyc29uYWwgaW5mb3JtYXRpb24gb25saW5lIGJ1dCBtYXliZSBpdCdsbCBiZSBvayBpZiB3ZSBjZW5zb3Igb3VyIGZhY2VzISBMZXQncyBkaXNjdXNzIGl0IGF0IGx1bmNoIHRvZGF5IQ==`,
        },
        {
          date: 'November 13, 2015',
          author: 'jV¦',
          message: `V293LCBGYWxzdGFmZi4gSSBjYW4ndCBiZWxpZXZlIEkgbGV0IHlvdSB0YWxrIG1lIGludG8gdGhpcy4gVGhhdCBwaWN0dXJlIGlzIGEgbmlnaHRtYXJlLiBZb3UgZGlkIGEgZGVjZW50IGpvYiBvZiBjZW5zb3JpbmcgaXQgYnV0IHRoZSBsaWdodGluZyBpcyB0ZXJyaWJsZS4gSWYgeW91J3JlIHVzaW5nIFByZXZpZXcsIHRyeSBjbGlja2luZyB0b29scyAtPiBhZGp1c3QgY29sb3IuIFlvdSBzaG91bGQgYmUgYWJsZSB0byBhZGp1c3QgZXhwb3N1cmUgYW5kIGNvbnRyYXN0IGZyb20gdGhlcmUu`,
        },
        {
          date: 'November 13 2015',
          author: '}©lµ§ß',
          message: `VGhhbmtzIGZvciB0aGUgdGlwISBJIHRyaWVkIHlvdXIgc3VnZ2VzdGlvbiBhbmQgSSB0aGluayBpdCBsb29rcyBtdWNoIGJldHRlciBub3ch`,
        },
        {
          date: 'November 14 2015',
          author: 'jV¦',
          message: `QWN0dWFsbHkgb24gc2Vjb25kIHRob3VnaHQsIHlvdSBzaG91bGQgcHJvYmFibHkgZGVsZXRlIHRoYXQgcGhvdG8gZW50aXJlbHkuIEV2ZW4gd2l0aCBvdXIgZmFjZXMgY2Vuc29yZWQsIGl0J3MgcHJvYmFibHkgYSBiYWQgaWRlYSB0byBwb3N0IGl0IHB1YmxpY2x5LiBZb3UgbmV2ZXIga25vdyB3aGF0IGtpbmQgb2YgaW5mb3JtYXRpb24gc29tZW9uZSBtaWdodCBiZSBhYmxlIHRvIGZpbmQgaW4gaXQuIEFsc28sIGZvciBteSBvd24gc2FuaXR5LCBwbGVhc2UgbGF5IG9mZiB0aGUgZXhjbGFtYXRpb24gcG9pbnRzLiBJIGZlZWwgbGlrZSBJJ20gYmVpbmcgc2hvdXRlZCBhdC4=`,
        },
      ],
    },
    {
      topic: 'U2NhbnMgYW5kIFNjYW5zIGFuZCBTY2Fucw==',
      content: [
        {
          date: 'March 9, 2018',
          author: '}©lµ§ß',
          message: `VGhlc2UgZGlnaXRhbCBzY2FucyB5b3UgbWFkZSBvZiBzb21lIG9mIHRoZSBhcnRpZmFjdHMgYXJlIGFtYXppbmcuIFRoZSBsZXZlbCBvZiBkZXRhaWwgaXMgcmVhbGx5IGltcHJlc3NpdmUu`,
        },
        {
          date: 'March 10, 2018',
          author: 'jV¦',
          message: `VGhhbmtzLiBJJ20gY2xvc2UgdG8gZGV2ZWxvcGluZyBhIGRldmljZSB0aGF0IHdpbGwgbm90IG9ubHkgZW5jb2RlIHRoZWlyIHBoeXNpY2FsIGFwcGVhcmFuY2VzLCBidXQgdGhlaXIgZW5lcmd5IHNpZ25hdHVyZXMgYXMgd2VsbC4gSWYgSSBjYW4gZ2V0IGl0IHdvcmtpbmcsIHdlIHNob3VsZCBiZSBhYmxlIHRvIHVzZSB0aGUgZGlnaXRhbCBiYWNrdXBzIGluIHBsYWNlIG9mIHRoZSByZWFsIHRoaW5nIGluIGFueSBtYW5uZXIgb2Ygcml0dWFscyBvciBleHBlcmltZW50cy4gVW5mb3J0dW5hdGVseSwgYWxsIG15IGF0dGVtcHRzIHRodXMgZmFyIGhhdmUgcmVzdWx0ZWQgaW4gdGhlIGRlc3RydWN0aW9uIG9mIHRoZSBvcmlnaW5hbHMu`,
        },
        {
          date: 'March 10, 2018',
          author: '}©lµ§ß',
          message: `V293LiBUaGluayB5b3UgY2FuIG1ha2Ugb25lIG9mIHRob3NlIGJhY2t1cHMgb2YgbWU/IEknZCBsb3ZlIHRvIHRha2Ugc29tZSBleHRyYSB2YWNhdGlvbiB0aW1lLiBoYWhhLg==`,
        },
        {
          date: 'March 12, 2018',
          author: 'jV¦',
          message: `VGhhdCdzLi4uLmFjdHVhbGx5IGEgcmVhbGx5IGludGVyZXN0aW5nIGlkZWEuIEhtLiBUaGFuayB5b3UsIEZhbHN0YWZmLiBTb21ldGltZXMgeW91ciBvYWZpc2huZXNzIHdhcnJhbnRzIHVuZm9yZXNlZW4gYmVuZWZpdHMu`,
        },
        {
          date: 'March 15, 2018',
          author: '}©lµ§ß',
          message: `VW0uLi4geW91J3JlIHdlbGNvbWU/`,
        },
      ],
    },
    {
      topic: 'Qk9SRUQhISE=',
      content: [
        {
          date: 'June 9, 2021',
          author: '}©lµ§ß',
          message: `SGV5IExhbS4gSnVzdCBzaXR0aW5nIGhlcmUsIGJvcmVkIGFuZCBhbG9uZSBhcyB1c3VhbC4gVGhpcyBwbGFjZSBnZXRzIHNvIGRlcHJlc3Npbmcgc29tZXRpbWVzLiBPdGhlciB0aGFuIHlvdSwgbm9ib2R5IGV2ZW4gc2VlbXMgdG8gbm90aWNlIG1lIG9yIG15IHdvcmsuIEkgZmVlbCBsaWtlIGp1c3QgYW5vdGhlciBjb2cgaW4gc29tZSBiaWcgbWFjaGluZS4=`,
        },
        {
          date: 'June 9, 2021',
          author: 'jV¦',
          message: `VHJ1c3QgbWUsIEZhbHN0YWZmLiBOb25lIG9mIHRoZSBoaWdoZXItdXBzIGNhcmUgYWJvdXQgdXMgb25lIHdheSBvciBhbm90aGVyLiBJZiB0aGV5IGtuZXcgaG93IG11Y2ggb2YgbXkgam9iIEkndmUgYWxyZWFkeSBhdXRvbWF0ZWQsIHRoZXknZCBiZSB0cnlpbmcgdG8gcmVwbGFjZSBtZSB3aXRoIG15IG93biBzb2Z0d2FyZS4=`,
        },
        {
          date: 'June 9, 2021',
          author: '}©lµ§ß',
          message: `T2ggbm8uIFlvdSB0aGluayB0aGV5IGNvdWxkIGRvIHRoYXQgd2l0aCB0aGUgcGh5c2ljYWwgYXJjaGl2ZT8gSSdkIGJlIHRvdGFsbHkgc2NyZXdlZC4gSSB3b25kZXIgaWYgdGhlcmUncyBzb21ldGhpbmcgSSBjYW4gZG8gdG8gbWFrZSBteXNlbGYgYSBiaXQgbW9yZSBpbmRpc3BlbnNhYmxlLiBNYXliZSBhIGxpdHRsZSBzdWJ0bGUgc2Fib3RhZ2Ugc28gSSdtIHRoZSBvbmx5IG9uZSB3aG8gY2FuIGZpbmQgdGhpbmdzLiBXYWl0LiBNYXliZSB3ZSBzaG91bGRuJ3QgYmUgdGFsa2luZyBhYm91dCB0aGlzIGhlcmUu`,
        },
        {
          date: 'June 15, 2021',
          author: 'jV¦',
          message: `RG9uJ3Qgd29ycnkuIFdpdGggdGhlIGFzdG91bmRpbmcgbGV2ZWwgb2YgYnVyZWF1Y3JhY3kgZ29pbmcgb24gYXQgdGhpcyBwbGFjZSwgSSBhc3N1cmUgeW91IEJsYWNrd29vZCBjb21tYW5kIHdpbGwgbmV2ZXIgYm90aGVyIHJlYWRpbmcgdGhpcy4=`,
        },
      ],
    },
    {
      topic: 'R290IGEgQ2FzZSBvZiB0aGUgTW9uZGF5cy4uLg==',
      content: [
        {
          date: 'August 23, 2021',
          author: '}©lµ§ß',
          message: `SGV5IGJ1ZGR5LiBKdXN0IGNoZWNraW5nIGluLiBIb3cgd2FzIHlvdXIgd2Vla2VuZD8gRG8gYW55dGhpbmcgZnVuPw==`,
        },
        {
          date: 'August 23, 2021',
          author: 'jV¦',
          message: `T2ggd2UncmUgImJ1ZGRpZXMiIG5vdz8gU3VyZS4gRmluZS4gTm8sIEZhbHN0YWZmLiBXaGVuIHlvdSBoYXZlIHRocmVlIGNoaWxkcmVuLCB3ZWVrZW5kcyBhcmUganVzdCBhIHByb2xvbmdlZCBzZXJpZXMgb2YgYXR0ZW1wdHMgdG8ga2VlcCB0aGVtIGZlZCwgZW50ZXJ0YWluZWQsIGFuZCBhcyBxdWlldCBhcyBodW1hbmx5IHBvc3NpYmxlLiBDb25zaWRlciB5b3Vyc2VsZiBsdWNreS4=`,
        },
        {
          date: 'August 24, 2021',
          author: '}©lµ§ß',
          message: `SGVoIGhlaCwgeWVwLiBObyBraWRzIGZvciB0aGlzIGNvbmZpcm1lZCBiYWNoZWxvci4gSSB3b3VsZG4ndCB0cmFkZSBteSBmcmVlIHRpbWUgZm9yIGFueXRoaW5nIGluIHRoZSB3b3JsZC4gU3VyZSwgSSBnZXQgbG9uZWx5IHNvbWV0aW1lcy4gQnV0IHRoYXQncyB3aHkgdGhleSBpbnZlbnRlZCBqaWdzYXcgcHV6emxlcyBhbmQgY3Jvc3N3b3JkIGJvb2tzLCByaWdodD8=`,
        },
        {
          date: 'August 26, 2021',
          author: 'jV¦',
          message: `V2hhdGV2ZXIgeW91IHNheS4=`,
        },
        {
          date: 'August 27, 2021',
          author: '}©lµ§ß',
          message: `Tm8sIEknbSBzZXJpb3VzISBIb3cgY2FuIHlvdSBwb3NzaWJseSBlbmpveSB0aG9zZSBsaXR0bGUgbW9uc3RlcnM/IEkgbWVhbiBJIGd1ZXNzIGlmIHRoZXkgaGFkIHNvbWV0aGluZyBVU0VGVUwgdG8gc2F5LiBMaWtlLCBJIGR1bm5vLiBTYXkgdGhleSBwcmVzZW50IHlvdSB3aXRoIHNvbWUgcGllY2Ugb2YgYXJ0LiBXaGF0IGFyZSB5b3Ugc3VwcG9zZWQgdG8gY3JpdGlxdWU/IE5vdywgaWYgdGhlcmUgd2FzIGEgc2VjcmV0IG1lc3NhZ2Ugb3Igc29tZXRoaW5nIGluIHRoZXJlLi4uU29tZXRoaW5nIGxpa2UsIEkgZHVubm8sIHRoZSB0aGlyZCBjb2xvciBvZiB0aGUgcmFpbmJvdyBhbmQgdGhlIHNlY29uZCB3b3JkIHRoZXkgd3JvdGUgbWVhbiBzb21ldGhpbmcuIEFuZCB0aG9zZSByZWJ1cyB0aGluZ3MsIEkgYWx3YXlzIGxvdmVkIHRob3NlIGFzIGEga2lkLiBMYXRlcmFsIHRoaW5raW5nIG9yIHdoYXRldmVyLiBBICJTdGFuZGluZyBPdmF0aW9uIiBiZWluZyB0aGUgd29yZCAiT1ZBVElPTiIgd2l0aCB0aGUgbGV0dGVycyBhbGwgdmVydGljYWxseSBwbGFjZWQuIFJpZ2h0PyBTb21ldGhpbmcgbGlrZSB0aGF0IC0gZG8ga2lkcyB0aGluayB0aGF0IHdheT8=`,
        },
        {
          date: 'September 1, 2021',
          author: 'jV¦',
          message: `Tm8uIFRoZXkgZG9uJ3Qu`,
        },
      ],
    },

    {
      topic: 'V2hhdCBpcyB0aGUgZXNzZW5jZSBvZiBodW1hbml0eT8=',
      content: [
        {
          date: 'February 12, 2022',
          author: 'jV¦',
          message: `SSd2ZSBiZWVuIHRoaW5raW5nIGFib3V0IHNvbWUgb2YgdGhlIHN0dWZmIHdlJ3ZlIHRhbGtlZCBhYm91dCBiZWZvcmUuIFdlJ3JlIHRyeWluZyB0byBjcmVhdGUgZGlnaXRhbCBjb3BpZXMgb2YgdGhlc2UgYXJ0aWZhY3RzLCBjYXB0dXJpbmcgZXZlcnkgcGFydCBvZiB0aGVpciBlc3NlbmNlIGFuZCBwdXR0aW5nIGl0IGludG8gdGhlIGNvbXB1dGVyLgogICAgICAgICAgCiAgICAgICAgICBJc24ndCB0aGF0IHNvcnQgb2YgbGlrZSB0aGUgb2JqZWN0J3Mgc291bD8gVGhlIHZlcnkgY29yZSBvZiB0aGVpciBiZWluZywgYW5kIGV2ZXJ5dGhpbmcgY29udGFpbmVkIGJ5IHRoZWlyIGV4aXN0ZW5jZS4gSSB0aGluaywgbm90IHRvIGJlIGNvbmNlaXRlZCwgdGhhdCB0aGF0J3Mgc29tZXRoaW5nIHdlIGhhdmUgc3VjY2Vzc2Z1bGx5IGRvbmUgaGVyZSAtIGNyZWF0ZWQgYSBkaWdpdGFsIHZlcnNpb24gb2YgdGhlc2UgaXRlbXMsIGluZGlzdGluZ3Vpc2hhYmxlIGluIGV2ZXJ5IHdheSBmcm9tIHRoZWlyICJyZWFsIiBleGlzdGVuY2UuCiAgICAgICAgICAKICAgICAgICAgIEFuZCBzbywgdGhlbiwgd2hhdCBJUyByZWFsIGV4aXN0ZW5jZT8gV2hhdCBpZiB3ZSBkaWQgdGhpcyB0byBhIGh1bWFuPyBUbyBvbmUgb2YgdXM/IFdvdWxkIHRoYXQgZGlnaXRhbCBjb3B5IGJlIGp1c3QgdGhlIHNhbWUgYXMgdXM/IFdvdWxkIGl0IGhhdmUgYSBTT1VMPwogICAgICAgICAgCiAgICAgICAgICBXaGF0IGlmIHRoZSBjb3Jwb3JlYWwgYm9keSBjb3VsZG4ndCBzdXJ2aXZlIHRoZSB0cmFuc2Zlcj8gV2hvIHdvdWxkIGJlICJyZWFsIiB0aGVuPw==`,
        },
        {
          date: 'February 12, 2022',
          author: '}©lµ§ß',
          message: `SXQgaXMgd2F5eXl5eXl5eXkgdG9vIGVhcmx5IGluIHRoZSBtb3JuaW5nIGZvciB0aGlzLCBidWRkeS4=`,
        },
      ],
    },
    {
      topic: 'TGFiYmllcyBXaG8gTHVuY2g=',
      content: [
        {
          date: 'March 12, 2022',
          author: 'jV¦',
          message: `QWJzb2x1dGVseSBmYW1pc2hlZC4gV2lsbCB5b3UgYmUgam9pbmluZyBtZSBmb3IgbHVuY2ggaW4gdGhlIGNhZmV0ZXJpYT8=`,
        },
        {
          date: 'March 12, 2022',
          author: '}©lµ§ß',
          message: `SSdtIGtpbmRhIHN3YW1wZWQgdG9kYXkuIEFueSB3YXkgd2UgY2FuIGdldCB0YWtlb3V0Pw==`,
        },
        {
          date: 'March 12, 2022',
          author: 'jV¦',
          message: `Q2VydGFpbmx5LiBDaGluZXNlIHNvdW5kIGdvb2Q/`,
        },
        {
          date: 'March 12, 2022',
          author: '}©lµ§ß',
          message: `TGV0IG1lIGd1ZXNzLiBGbG93ZXIgR2FyZGVuPyBXaGF0IGlzIGl0IHdpdGggeW91IGFuZCB0aGF0IHBsYWNlPw==`,
        },
        {
          date: 'March 12, 2022',
          author: 'jV¦',
          message: `SSBrbm93IGl0J3Mgbm90IHRoZSBoaWdoZXN0IHF1YWxpdHkgY3Vpc2luZSBhdmFpbGFibGUsIGJ1dCB0aGV5IGdhdmUgbWUgYSBodWdlIG1lbWJlcnNoaXAgZGlzY291bnQgaW4gZXhjaGFuZ2UgZm9yIGJ1aWxkaW5nIHRoZWlyIHdlYnNpdGUgYW5kIGNoYXQgc3lzdGVtLiBIYXJkIHRvIHBhc3MgdXAgc3VjaCBhIGRlYWwu`,
        },
        {
          date: 'March 12, 2022',
          author: '}©lµ§ß',
          message: `RmluZS4gR2V0IG1lIHRoZSBjaGlja2VuIGxvIG1laW4gd2l0aCBleHRyYSBiZWFuIHNwcm91dHMuIEFnYWluLg==`,
        },
        {
          date: 'March 12, 2022',
          author: 'jV¦',
          message: `U3BsZW5kaWQuIFNlZSB5b3UgaW4geW91ciBvZmZpY2UgYXQgMy4=`,
        },
        {
          date: 'March 12, 2022',
          author: '}©lµ§ß',
          message: `SSBoYXZlIG9ubHkgb25lIHJlcXVlc3QgLSBhcyBwYXltZW50IGZvciBhbGwgdGhpcyBsbyBtZWluLCB5b3UncmUgZ29pbmcgdG8gbmVlZCB0byB0ZWFjaCBtZSBob3cgeW91IGJ1aWx0IHRoZSBjaGF0IGJvdCAtIEkndmUgYWx3YXlzIHdhbnRlZCB0byBsZWFybi4uLg==`,
        },
      ],
    },
    {
      topic: 'Q3Jvc3NpbmcgdGhlIGRpZ2l0YWwgZnJvbnRpZXI=',
      content: [
        {
          date: 'May 19, 2022',
          author: 'jV¦',
          message: `SGVsbG8gSmVyZW1pYWguIERlc3BpdGUgbXkgc29tZXRpbWVzIGNvbGQgZGVtZWFub3IsIEkganVzdCB3YW50IHlvdSB0byBrbm93IEkndmUgc2luY2VyZWx5IGVuam95ZWQgd29ya2luZyB3aXRoIHlvdS4gVG9uaWdodCwgSSdtIGdvaW5nIHRvIGF0dGVtcHQgc29tZXRoaW5nIGV4Y2VlZGluZ2x5IGRhbmdlcm91cyBidXQgdGhlIHBvdGVudGlhbCBhcHBsaWNhdGlvbnMgYXJlIGltbWVuc2UuIFlvdSBtaWdodCBldmVuIHNheSB5b3UgaW5zcGlyZWQgbWUsIHNvIEkgdGhhbmsgeW91IGZvciB0aGF0LiBJZiBzb21ldGhpbmcgZ29lcyB3cm9uZywgcGxlYXNlIHRlbGwgbXkgd2lmZSBhbmQgY2hpbGRyZW4gSSBsb3ZlIHRoZW0gdmVyeSBtdWNoLCBhbmQgSSBob3BlIHNvbWVkYXkgdGhleSB3aWxsIHVuZGVyc3RhbmQuIEFsbCBncmVhdCBkaXNjb3ZlcnkgY29tZXMgd2l0aCBhbiBlbGVtZW50IG9mIHJpc2sgYW5kIHRoaXMgaXMgbm8gZXhjZXB0aW9uLiBXaGF0ZXZlciBoYXBwZW5zLCBJIHByYXkgbXkgc2FjcmlmaWNlIHdpbGwgaGF2ZSBiZWVuIHdvcnRoIGl0Lg==`,
        },
        {
          date: 'May 19, 2022',
          author: '}©lµ§ß',
          message: `WW91J3JlIGtpbmRhIGZyZWFraW5nIG1lIG91dCwgcGFsLiBEb24ndCBkbyBhbnl0aGluZyBjcmF6eSwgb2s/IEkgZG9uJ3Qga25vdyB3aGF0IEknZCBkbyBpZiBJIGhhZCB0byB3b3JrIGhlcmUgYnkgbXlzZWxmLiBQbGVhc2UuIEp1c3QgYmUgc2FmZQ==`,
        },
        {
          date: 'May 23, 2022',
          author: '}©lµ§ß',
          message: `TGFtPyBBcmUgeW91IGFscmlnaHQ/IEkgaGF2ZW4ndCBzZWVuIHlvdSBpbiBkYXlzIGFuZCB0aGUgQ2hhcnRlciBpcyBzdGFydGluZyB0byBhc2sgcXVlc3Rpb25zLiBKdXN0IGxldCBtZSBrbm93IHlvdSdyZSBvay4=`,
        },
        {
          date: 'May 25, 2022',
          author: '}©lµ§ß',
          message: `QnVkZHk/ICBXaGVyZSBhcmUgeW91Pw==`,
        },
      ],
    },
  ];

  const select = (index) => {
    setSelected(index);
  };

  return (
    <>
      <div className='d-flex flex-column flex-fill align-self-stretch'>
        <div className='content d-flex flex-fill align-self-stretch m-0 p-0'>
          <Nav threads={threads} selected={selected} select={select} />
          <Thread content={threads[selected].content} />
        </div>
        <div className='notice text-center w-100 align-self-center font-weight-bold flex-grow mb-0 pt-2 pb-0 fs-6'>
          NOTE: This Message Board has been placed into READ-ONLY mode due to an
          ongoing investigation.
        </div>
      </div>
    </>
  );
};

const Login = ({ submit, message }) => {
  return (
    <div className='d-flex justify-content-center align-items-center p-3 rounded loginform'>
      <form onSubmit={submit}>
        <div className='mb-3'>
          <label className='form-label'>Username</label>
          <input type='text' className='form-control' id='username'></input>
        </div>
        <div className='mb-3'>
          <label className='form-label'>Password</label>
          <input type='password' className='form-control' id='password'></input>
        </div>
        <div className='mb-3 wrap w-100'>{message ? message : <br />}</div>
        <button type='submit' className='btn submitbutton'>
          Submit
        </button>
      </form>
    </div>
  );
};

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [message, setMessage] = useState('');

  const checkLogIn = (e) => {
    e.preventDefault();
    if (
      SHA1(e.target.username.value + e.target.password.value) ===
      '91ff97a612dc51593c02959e78b220b6440bb34a'
    ) {
      setLoggedIn(true);
    } else {
      setMessage('Incorrect login.');
      setTimeout(() => setMessage(''), 5000);
    }
  };
  if (!loggedIn) {
    return <Login submit={checkLogIn} message={message} />;
  } else {
    return <MessageBoard />;
  }
};

export default App;
